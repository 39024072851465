import styles from "./App.module.scss";
import React from "react";
import { useWindowSize } from "./Hooks";
import Home from "./Pages/Home/Home";
import AboutMe from "./Pages/AboutMe/AboutMe";

export default function App() {
  return (
    <div className={styles.App}>
      <Home />
      {/* <AboutMe/> */}
    </div>
  );
}
