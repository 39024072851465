import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollSnap from 'scroll-snap'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

//scroll-snap (ne fonctionne pas pour le moment)

// const snapConfig = {
//   /**
//    * snap-destination for x and y axes
//    * should be a valid css value expressed as px|%|vw|vh
//    */
//   snapDestinationX: '0%',
//   snapDestinationY: '90%',
//   /**
//    * time in ms after which scrolling is considered finished
//    * [default: 100]
//    */
//   timeout: 100,
//   /**
//    * duration in ms for the smooth snap
//    * [default: 300]
//    */
//   duration: 300,
//   /**
//    * threshold to reach before scrolling to next/prev element, expressed as a percentage in the range [0, 1]
//    * [default: 0.2]
//    */
//   threshold: 0.2,
//   /**
//    * when true, the scroll container is not allowed to "pass over" the other snap positions
//    * [default: false]
//    */
//   snapStop: false,
//   /**
//    * custom easing function
//    * [default: easeInOutQuad]
//    * for reference: https://gist.github.com/gre/1650294
//    * @param t normalized time typically in the range [0, 1]
//    */
// }

// function callback() {
//   console.log('element snapped')
// }

// const element = document.body
// const snapObject = new ScrollSnap(element, snapConfig)

// snapObject.bind(callback)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
