import styles from "./Home.module.scss";
import React from "react";
import { useWindowSize } from "../../Hooks";

export default function Home() {
  const windowSize = useWindowSize();

  function ImageHandler({
    imageId,
    viewport = windowSize.width,
    viewportBreakpoint = 1200,
  }) {
    if (viewport > viewportBreakpoint) {
      return <img src={`img/${imageId}PC.svg`} id={styles[imageId]} />;
    } else {
      return <img src={`img/${imageId}TEL.svg`} id={styles[imageId]} />;
    }
  }

  return (
    <div className={styles.HomeSection}>
      <div
        style={{
          textAlign: "center",
          fontSize: "1rem",
          backgroundColor: "#e76f51",
          width: "100%",
          color: "white",
          height: "5rem",
        }}
      >
        Note: I'm currently rebuilding this old portfolio, stay tuned!
      </div>
      <div className={styles.Content}>
        {/* <div className={styles.LeftRow}> */}
        <div className={styles.Text}>
          <h2>
            <span>
              {"<"}
              <span>{"Hello!"}</span>
              {"/>"}
            </span>
            {" I'm Nohé,"}
          </h2>
          <p className={styles.Pa}>
            fullstack R&D engineer,
            <br /> tech enthusiast {"&"} innovator.
          </p>
        </div>

        <div className={styles.Links}>
          <div className={styles.Link}>
            {/* <button>Academic<br/> portfolio</button> */}
            <a
              href="https://nohehf.notion.site/nohehf/Hello-I-m-Noh-Hinniger-Foray-09d3f57b4b88445aa10cd00c216afdf3"
              target="_blank"
              rel="noreferrer"
            >
              Academic
              <br /> portfolio
            </a>
            <ImageHandler imageId="school" />
          </div>

          <div className={styles.Link}>
            <a
              href="https://www.github.com/nohehf"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <ImageHandler imageId="code" />
          </div>

          <div className={styles.Link}>
            <a
              href="https://www.linkedin.com/in/noh%C3%A9-hinniger-foray-063273221"
              target="_blank"
              rel="noreferrer"
            >
              Linkedin
            </a>
            <ImageHandler imageId="pro" />
          </div>
        </div>

        {/* </div> */}

        <div className={styles.RightRow}>
          <img src="img/photo.png"></img>
        </div>
      </div>

      <div
        className={styles.Footer}
        style={{ backgroundImage: "url('img/waves.svg')" }}
      >
        {/* <div>
            more!
            <img src="img/arrowDown.svg"/>
          </div> */}
      </div>
    </div>
  );
}
